(function () {
  app.controller("SignUpCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.user = {};
      $scope.booking = {};
      $scope.ambassador_types = {
        ambassador: "0",
        experience_creator: "1",
        cpo: "2",
      };
      $scope.ambassador_obj = {
        ambassador_type: "0",
        status: "0",
      };
      $scope.school_ids = [];
      $scope.is_waitlist = false;
      $scope.states = [];
      $scope.cities = [];
      $scope.list_schools = [];
      $scope.school_state_data = [];
      $scope.school_city_data = [];
      $scope.partner_schools = [];
      $scope.partner_school_ids = [];
      $scope.partner_states = [];
      $scope.partner_cities = [];
      $scope.partner_state_data = {};
      $scope.partner_city_data = {};
      $scope.term_accepted = false;
      $scope.child_list = [];
      $scope.parent_list = [];

      $scope.genders = {};
      $scope.school = {};
      $scope.organization = {};
      $scope.is_parent = true;

      $scope.error_message = "";
      $scope.student_error_message = "";
      $scope.parent_error_message = "";
      $scope.customer_type_id = null;

      $scope.init = function () {};

      $scope.addParent = function (index) {
        $scope.parent_list.push({
          first_name: "",
          last_name: "",
          birthday: "",
          email: "",
        });
        $timeout(function () {
          inputMaskPhoneNumber();
        }, 300);
      };

      $scope.removeParent = function (index) {
        $scope.parent_list.splice(index, 1);
      };

      $scope.addChild = function (index) {
        $scope.child_list.push({
          first_name: "",
          last_name: "",
          birthday: "",
          email: "",
        });
        $timeout(function () {
          $("#child" + index).datepicker({
            format: "yyyy-mm-dd",
            autoclose: true,
          });
          inputMaskPhoneNumber();
        }, 300);
      };

      $scope.removeChild = function (index) {
        $scope.child_list.splice(index, 1);
      };

      $scope.validateDuplicateEmail = function () {
        $scope.student_error_message = "";
        $scope.parent_error_message = "";
        $scope.error_message = "";
        var result = true;

        for (var i = 0; i < $scope.child_list.length; i++) {
          if ($scope.user.email.trim() == $scope.child_list[i].email.trim()) {
            $scope.student_error_message =
              "Emails cannot be the same with user email!";
            result = false;
            return result;
          }
        }

        for (var i = 0; i < $scope.parent_list.length; i++) {
          if ($scope.user.email.trim() == $scope.parent_list[i].email.trim()) {
            $scope.parent_error_message =
              "Emails cannot be the same with user email!";
            result = false;
            return result;
          }
        }

        for (var i = 0; i < $scope.child_list.length - 1; i++) {
          for (var j = i + 1; j < $scope.child_list.length; j++) {
            if (
              $scope.child_list[i].email.trim() ==
              $scope.child_list[j].email.trim()
            ) {
              $scope.student_error_message = "Emails cannot be the same!";
              result = false;
              return result;
            }
          }
        }
        for (var i = 0; i < $scope.parent_list.length - 1; i++) {
          for (var j = i + 1; j < $scope.parent_list.length; j++) {
            if (
              $scope.parent_list[i].email.trim() ==
              $scope.parent_list[j].email.trim()
            ) {
              $scope.parent_error_message = "Emails cannot be the same!";
              result = false;
              return result;
            }
          }
        }
        for (var i = 0; i < $scope.child_list.length; i++) {
          for (var j = 0; j < $scope.parent_list.length; j++) {
            if (
              $scope.child_list[i].email.trim() ==
              $scope.parent_list[j].email.trim()
            ) {
              $scope.error_message = "Emails cannot be the same!";
              result = false;
              return result;
            }
          }
        }
        return result;
      };

      $scope.submitForm = function () {
        // if (!$scope.validateDuplicateEmail()){
        //     return;
        // }
        
        var data = {
          user: $scope.user,
          school: typeof ($scope.school) == 'object' ? $scope.school : $scope.schools.find(school => school.id == $scope.school),
          organization: $scope.organization,
          term_accepted: $scope.term_accepted,
          // is_parent: $scope.is_parent,
          user_type: $scope.user_type,
          customer_type_id: $scope.customer_type_id,
          trip_date: $scope.selected_trip_date,
          auto_select_trip_date: $scope.auto_select_trip_date,
          relationship_id: $scope.relationship_id,
          ambassador_id: $scope.ambassador_id,
          user_amb_id: $scope.user_amb ? $scope.user_amb.id : null,
          invite_signup_link: $scope.invite_signup_link,
        };
        // if ($scope.school)
        //     data.is_waitlist = $scope.school.id != -1 && !$scope.school_ids.includes($scope.school.id);

        // if (!$scope.relationship_id) {
        //     if (($scope.is_parent && $scope.current_event.parent_invite_child_reg) || (!$scope.is_parent && $scope.current_event.child_invite_child_reg)) {
        //         data.child_list = $.grep($scope.child_list, function (x, i) {
        //             return x.first_name != '' || x.last_name != '' || x.birthday != '' || x.email != '';
        //         });
        //     }
        //
        //     if (($scope.is_parent && $scope.current_event.parent_invite_parent_reg) || (!$scope.is_parent && $scope.current_event.child_invite_parent_reg)) {
        //         data.parent_list = $.grep($scope.parent_list, function (x, i) {
        //             return x.first_name != '' || x.last_name != '' || x.birthday != '' || x.email != '';
        //         });
        //     }
        // }
        //
        // if ($scope.is_parent)
        //     data.school_activities = $scope.school_activities

        $.post($scope.register_url, data, function (rs) {
          if (rs.success) {
          } else {
            var errors = rs.errors;
            $timeout(function () {
              $scope.errors = errors;

              if (errors && errors != "" && !angular.equals(errors, {})) {
                setTimeout(function () {
                  var targetOffset = $(".invalid-feedback:first").offset();

                  if (targetOffset) {
                    let top = targetOffset.top - 150;
                    $("html, body").animate({ scrollTop: top }, 100);
                  }
                }, 1000);
              }
            });
            if (rs.message && rs.message != "") {
              bootbox.alert(rs.message);
            }

            // if (errors && errors != '' && !angular.equals(errors, {})) {
            //     var user_errors = errors.user;
            //     var school_errors = errors.school;
            //     var child_errors = errors.child_list;
            //     var msg = '';
            //
            //     if(user_errors){
            //         msg += '<li class="font-weight-bold pt-2 pb-1 ml-n4">User Information</li>';
            //         $.each(user_errors, function (k,v) {
            //             msg += '<li> <i class="fa-li fa fa-angle-double-right small mt-1"></i>' + v + '</li>'
            //         });
            //     }
            //
            //     if(school_errors){
            //         msg += '<li class="font-weight-bold pt-2 pb-1 ml-n4">School Information</li>';
            //         $.each(school_errors, function (k,v) {
            //             msg += '<li> <i class="fa-li fa fa-angle-double-right small mt-1"></i>' + v + '</li>'
            //         });
            //     }
            //
            //     if(child_errors){
            //         msg += '<li class="font-weight-bold pt-2 pb-1 ml-n4">Grad Information</li>';
            //         $.each(child_errors, function (i, item) {
            //             $.each(item, function (k,v) {
            //                 msg += '<li> <i class="fa-li fa fa-angle-double-right small mt-1"></i>' + v + '</li>'
            //             });
            //         });
            //     }
            //
            //     bootbox.dialog({
            //         title: '<span class="text-danger">Error!</span>',
            //         message: '<ul class="fa-ul">' + msg + '</ul>',
            //         closeButton: true
            //     });
            // }
          }
        });
      };

      $scope.getStates = function () {
        if ($scope.filter_school) {
          return;
        }

        var country_id = $.trim($scope.school.country_id);

        if (country_id == "") {
          $scope.states = [];
          $scope.cities = [];
          $scope.list_schools = [];
          $scope.schools = [];
          $scope.ambassadors = [];
          return false;
        }

        if ($scope.states.length > 0) {
          $scope.states = null;
        }

        $.post(
          $scope.get_states_url,
          { id: $scope.school.country_id },
          function (rs) {
            if (rs) {
              $timeout(function () {
                $scope.states = rs;
                $scope.cities = [];
                $scope.list_schools = [];
                $scope.schools = [];
                $scope.ambassadors = [];
              });
            }
          }
        );
      };

      $scope.getCities = function () {
        if ($scope.filter_school) {
          return;
        }

        if ($.trim($scope.school.state_id) == "") {
          $scope.cities = [];
          $scope.list_schools = [];
          $scope.schools = [];
          $scope.ambassadors = [];
          return false;
        }

        if ($scope.cities.length > 0) {
          $scope.school.city = null;
        }

        $.post(
          $scope.get_cities_url,
          { state_id: $scope.school.state_id },
          function (rs) {
            if (rs) {
              $timeout(function () {
                // rs = rs.map(name => name.toUpperCase());
                $scope.cities = rs;
                $scope.list_schools = [];
                $scope.schools = [];
                $scope.ambassadors = [];

                if ($.trim($scope.school.city) != "") {
                  $scope.getSchools();
                }
              });
            }
          }
        );
      };

      $scope.getSchools = function () {
        if ($scope.filter_school) {
          return;
        }

        if ($.trim($scope.school.city) == "") {
          $scope.list_schools = [];
          $scope.schools = [];
          $scope.school.school = null;
          $scope.is_partner_school = false;
          return false;
        }

        var opt = {
          state_id: $scope.school.state_id,
          city: $scope.school.city,
          is_partner: false,
        };

        // if (!$scope.is_parent) {
        //     opt['all'] = true;
        // }

        $.post($scope.get_schools_url, opt, function (rs) {
          if (rs) {
            $timeout(function () {
              var schools = $scope.partner_schools.concat(rs.schools);
              $scope.schools = schools;
              $scope.list_schools = $scope.schools;
              $scope.school_state_data = rs.school_state_data;
              $scope.school_city_data = rs.school_city_data;

              if (!$scope.is_parent) {
                $scope.getAmbassadors();
              }
            });
          }
        });
      };

      $scope.filterSchools = function (school_name) {
        if (
          $scope.school &&
          ($scope.is_college ||
            ($scope.school.state_id != "" && $scope.school.city != ""))
        ) {
          $timeout(function () {
            $scope.schools = $.grep($scope.list_schools, function (x, i) {
              return (
                x.id == -1 ||
                $scope.partner_school_ids.indexOf(x.id) > -1 ||
                x.text.toLowerCase().indexOf(school_name.toLowerCase()) > -1
              );
            });
          });
        }
      };

      $scope.filterOrganizations = function (organization_name) {
        $timeout(function () {
          $scope.organizations = $.grep(
            $scope.list_organizations,
            function (x, i) {
              return (
                x.id == -1 ||
                x.text.toLowerCase().indexOf(organization_name.toLowerCase()) >
                  -1
              );
            }
          );
        });
      };

      $scope.getAmbassadors = function () {
        if ($.trim($scope.school.id) == "" || $scope.school.id == -1) {
          $scope.ambassadors = [];
          return false;
        }

        // auto select state & city when select a partner school

        if ($scope.partner_school_ids.indexOf($scope.school.id) > -1) {
          var partner_state_id = $scope.partner_state_data[$scope.school.id];
          var partner_city = $scope.partner_city_data[$scope.school.id];
          var state_ids = [];
          $.each($scope.states, function (i, x) {
            state_ids.push(x.id);
          });

          if (state_ids.indexOf(partner_state_id) == -1) {
            var partner_states = $.grep($scope.partner_states, function (x, i) {
              return x.id == partner_state_id;
            });
            $scope.states = partner_states.concat($scope.states);
          }

          if ($scope.cities.indexOf(partner_city) == -1) {
            var partner_cities = $.grep($scope.partner_cities, function (x, i) {
              return x == partner_city;
            });
            $scope.cities = partner_cities.concat($scope.cities);
          }

          $scope.school.state_id = partner_state_id;
          $scope.school.city = partner_city;
        } else {
          var state_id = $scope.school_state_data[$scope.school.id];
          var city = $scope.school_city_data[$scope.school.id];
          $scope.school.state_id = state_id;
          $scope.school.city = city;
        }

        setTimeout(() => {
          $('[ng-model="school.state_id"], [ng-model="school.city"]').select2();
        }, 300);

        // $.post($scope.get_ambassadors_url, {
        //     school_id: $scope.school.id
        // }, function (rs) {
        //     if (rs) {
        //         $timeout(function () {
        //             $scope.ambassadors = rs;
        //         });
        //     }
        // });
      };

      //ambassador form
      $scope.submitAmbassadorForm = function () {
        var data = {
          user: $scope.user,
          school: typeof ($scope.school) == 'object' ? $scope.school : $scope.schools.find(school => school.id == $scope.school),
          organization: $scope.organization,
          term_accepted: $scope.term_accepted,
          ambassador: $scope.ambassador_obj,
          invitation_id: $scope.invitation ? $scope.invitation.id : null,
          inviter_code: $scope.inviter_code,
          trip_date: $scope.selected_trip_date,
          booking_hotel_room_type: $scope.booking.hotel_room_type,
          booking_trip_date: $scope.booking.trip_date
        };

        $.post($scope.register_url, data, function (rs) {
          if (rs.success) {
          } else {
            var errors = rs.errors;
            $timeout(function () {
              $scope.errors = errors;

              if (errors && errors != "" && !angular.equals(errors, {})) {
                setTimeout(function () {
                  var targetOffset = $(".invalid-feedback:first").offset();

                  if (targetOffset) {
                    let top = targetOffset.top - 150;
                    $("html, body").animate({ scrollTop: top }, 100);
                  }
                }, 1000);
              }
            });

            if (rs.message && rs.message != "") {
              bootbox.alert(rs.message);
            }
          }
        });
      };
    },
  ]);
}.call(this));
